import { Component } from "react";



class AboutTwo extends Component {
    render() { 
        return (
            <section className="about padding-top padding-bottom bg__primary">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="about__content" data-aos="fade-up" data-aos-duration="1000">
                                    <h2>Art x Cute Monster</h2>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas de leniti within voluptatibus officia! Ipsum porro repellendus quis fuga illooxe officia, amet rem culpa quidem magni incidunt vitae accusamus.</p>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas de leniti within voluptatibus officia! Ipsum porro repellendus quis fuga illooxe officia, amet rem culpa quidem magni incidunt vitae accusamus.</p>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas de leniti within voluptatibus officia! Ipsum porro repellendus quis fuga illooxe officia, amet rem culpa quidem magni incidunt vitae accusamus.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about__thumb" data-aos="fade-up" data-aos-duration="1500">
                                    <img src="assets/images/art/01.png" alt="About Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default AboutTwo;