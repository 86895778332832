import { Component } from "react";


const title = "Frequently Asked Questions";
const desc = "Entering The Cute Monster Society means joining an family full of people who believe in the future of Cryptocurrencies and the Blockchain technology.";


class Faq extends Component {
    render() { 
        return (
            <section id="faq" className="faq padding-top padding-bottom">
                <div className="container">
                    <div className="section-header" data-aos="fade-zoom-in" data-aos-duration="1000">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="faq__wrapper">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="accordion" id="faqAccordion2">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in" data-aos-duration="600">
                                                <div className="accordion__header" id="faq1">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody1"
                                                        aria-expanded="false" aria-controls="faqBody1">
                                                        01
                                                        When is the official launch? <span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody1" className="accordion-collapse collapse" aria-labelledby="faq1"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Cute Monster approximatey 6-8 weeks after
                                                        the official launch
                                                        02
                                                        What will the mint price be?.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in" data-aos-duration="800">
                                                <div className="accordion__header" id="faq2">
                                                    <button className="accordion__button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#faqBody2" aria-expanded="true"
                                                        aria-controls="faqBody2">
                                                        02
                                                        What will the mint price be? <span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody2" className="accordion-collapse collapse show"
                                                    aria-labelledby="faq2" data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Cute Monster approximatey 6-8 weeks after
                                                        the official launch
                                                        02
                                                        What will the mint price be?.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in" data-aos-duration="1000">
                                                <div className="accordion__header" id="faq3">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody3"
                                                        aria-expanded="false" aria-controls="faqBody3">
                                                        03
                                                        How does the raffle work?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody3" className="accordion-collapse collapse" aria-labelledby="faq3"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Cute Monster approximatey 6-8 weeks after
                                                        the official launch
                                                        03
                                                        How does the raffle work?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in" data-aos-duration="1200">
                                                <div className="accordion__header" id="faq4">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody4"
                                                        aria-expanded="false" aria-controls="faqBody4">
                                                        04
                                                        How do I get a FREE Cute Monster?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody4" className="accordion-collapse collapse" aria-labelledby="faq4"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Crypto Hero approximatey 6-8 weeks after
                                                        the official launch
                                                        04
                                                        How do I get a FREE Cute Monster?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in" data-aos-duration="1400">
                                                <div className="accordion__header" id="faq5">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody5"
                                                        aria-expanded="false" aria-controls="faqBody5">
                                                        05
                                                        How do I get whitelisted?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody5" className="accordion-collapse collapse" aria-labelledby="faq5"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Cute Monster approximatey 6-8 weeks after
                                                        the official launch
                                                        05
                                                        How do I get whitelisted?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in" data-aos-duration="1600">
                                                <div className="accordion__header" id="faq6">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody6"
                                                        aria-expanded="false" aria-controls="faqBody6">
                                                        06
                                                        How can I use my NFT ?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody6" className="accordion-collapse collapse" aria-labelledby="faq6"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Cute Monster approximatey 6-8 weeks after
                                                        the official launch
                                                        06
                                                        How can I use my NFT ?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Faq;