import { Component } from "react";


const title = "CuteMonster";
const desc = "7777 Unique NFT Items Cute Monster";


let socialList = [
    {
        iconName: 'fab fa-twitter',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-discord',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-twitch',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-instagram',
        siteLink: '#',
    },
]

class Footer extends Component {
    render() { 
        return (
            <footer className="footer bg__primary">
                <div className="footer__wrapper padding-top padding-bottom">
                    <div className="container">
                        <div className="footer__content text-center">
                            <h3>{title}</h3>
                            <p>{desc}</p>
                            <ul className="social justify-content-center">
                                {socialList.map((val, i) => (
                                    <li className="social__item" key={i}>
                                        <a href={val.siteLink} className="social__link"><i className={val.iconName}></i></a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;