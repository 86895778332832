import { Component } from "react";


const title = "Welcome to Cute Monster";
const desc = "6,999 unique NFTs designed for the first-ever miniseries on the Metaverse";


let bannerList = [
    {
        imgUrl: 'assets/images/banner/01.png',
        imgAlt: 'Monster Image',
    },
    {
        imgUrl: 'assets/images/banner/02.png',
        imgAlt: 'Monster Image',
    },
    {
        imgUrl: 'assets/images/banner/03.png',
        imgAlt: 'Monster Image',
    },
    {
        imgUrl: 'assets/images/banner/04.png',
        imgAlt: 'Monster Image',
    },
    {
        imgUrl: 'assets/images/banner/05.png',
        imgAlt: 'Monster Image',
    },
]

class Banner extends Component {
    render() { 
        return (
            <section id="home" className="banner">
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row">
                            <div className="col">
                                <div className="banner__content" data-aos="zoom-in" data-aos-duration="1000">
                                    <h1>{title}</h1>
                                    <p>{desc}</p>
                                </div>
                                <div className="banner__images" data-aos="zoom-in-up" data-aos-duration="1000">
                                    <ul className="banner__images-list">
                                        {bannerList.map((val, i) => (
                                            <li className="banner__images-item" key={i}>
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Banner;