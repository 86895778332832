import { Component } from "react";
import { Link } from "react-router-dom";

const btnText = "Join Our Discord";


let socialList = [
    {
        iconName: 'fab fa-twitter',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-discord',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-twitch',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-instagram',
        siteLink: '#',
    },
]



class Header extends Component {
    closeMenu() {
        document.querySelector('.navbar-toggler').classList.add('collapsed');
        document.querySelector('.navbar-collapse').classList.remove('show');
    }


    render() { 
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 200) {
                document.querySelector('.header').classList.add(['header--fixed'], ['animated'], ['fadeInDown'])
            }else{
                document.querySelector('.header').classList.remove(['header--fixed'], ['animated'], ['fadeInDown'])
            }
        });
        
        return (
            <header className="header">
                <div className="container">
                    <nav className="navbar navbar-expand-xl">
                        <Link className="navbar-brand" to="/"><img src="assets/images/logo/logo.png" alt="Anftiland" /></Link>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler--icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link" aria-current="page" href="#home" onClick={this.closeMenu}>Home</a>
                                <a className="nav-link" href="#roadmap" onClick={this.closeMenu}>Roadmap</a>
                                <a className="nav-link" href="#artist" onClick={this.closeMenu}>Artist</a>
                                <a className="nav-link" href="#team" onClick={this.closeMenu}>Team</a>
                                <a className="nav-link" href="#faq" onClick={this.closeMenu}>Faq</a>
                            </div>
                            <div className="social-btns">
                                <ul className="social">
                                    {socialList.map((val, i) => (
                                        <li className="social__item" key={i}>
                                            <a href={val.siteLink} className="social__link"><i className={val.iconName}></i></a>
                                        </li>
                                    ))}
                                </ul>
                                <a href="#" className="default-btn">
                                    <span>{btnText}</span>
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}
 
export default Header;